import React from 'react'

export default class Logos extends React.Component {
  render() {
    return (
      <div>
        <div className="row mt-n5">
          <div className="col-4 col-md-2 mt-5">
            <div
              className="img-fluid svg-shim mx-auto"
              style={{ maxWidth: 90 + 'px' }}
            >
              <img src="/img/logos/flanzwrites.png" />
            </div>
          </div>
          <div className="col-4 col-md-2 mt-5 d-flex align-center">
            <div
              className="img-fluid svg-shim mx-auto mt-2"
              style={{ maxWidth: 100 + 'px' }}
            >
              <img src="/img/logos/envy.png" />
            </div>
          </div>
          <div className="col-4 col-md-2 mt-5 d-flex align-center">
            <div
              className="img-fluid svg-shim mx-auto mt-5"
              style={{ maxWidth: 150 + 'px' }}
            >
              <img src="/img/logos/mutations.png" />
            </div>
          </div>
          <div className="col-4 col-md-2 mt-5">
            <div
              className="img-fluid svg-shim mx-auto mt-5"
              style={{ maxWidth: 150 + 'px' }}
            >
              <img src="/img/logos/woo.svg" />
            </div>
          </div>
          <div className="col-4 col-md-2 mt-5 d-flex align-center">
            <div
              className="img-fluid svg-shim mx-auto mt-4"
              style={{ maxWidth: 150 + 'px' }}
            >
              <img src="/img/logos/mozilla.svg" />
            </div>
          </div>
          <div className="col-4 col-md-2 mt-5 d-flex align-center">
            <div
              className="img-fluid svg-shim mx-auto mt-4"
              style={{ maxWidth: 150 + 'px' }}
            >
              <img src="/img/logos/iridium.png" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
